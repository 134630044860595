.audio-player {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f3f3f3;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    max-width: 100px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.audio-player h4 {
    margin: 0 0 10px;
    color: #333;
    font-size: 1.2em;
}

.play-pause-btn {
    background: #00a9c3;
    border: none;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 1.1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    transition: background 0.3s;
}

.play-pause-btn:hover {
    background: #007c99;
}

