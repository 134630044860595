@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Changa:wght@600&family=Roboto+Flex:opsz,slnt,wdth,wght,GRAD,XOPQ,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC@8..144,-10..0,25..151,100..1000,-200..150,27..175,323..603,25..135,649..854,-305..-98,560..788,416..570,528..760&display=swap');

body {
  margin: 0;
  font-family: "Arial", sans-serif;
  text-align: center;
  background: linear-gradient(to bottom, #f3f3f3, #e0e0e0);
  color: #333;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  padding: 20px;
  background: #f3f3f3;
  font-size: 1.2em;
  color: #555;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.header h1 {
  font-family: 'Berkshire Swash', cursive;
  font-size: 2.2em;
  margin-bottom: 20px;
}

.main-content h2 {
  font-family: 'Changa', sans-serif;
  font-size: 2em;
  margin-bottom: 10px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.audio-player {
  margin-top: 20px;
  background: #00a9c3;
}

.footer {
  font-family: 'Changa', sans-serif;
  font-weight: 300;
  background: #00a9c3;
  color: #fff;
  padding: 10px 0;
  font-size: 0.9em;
}

.footer h3 {
  font-family: 'Berkshire Swash', cursive;
  font-weight: 100;
  font-size: 1.2em;
  margin-bottom: 10px;
}